import React from "react";
import Api from "../../../../../../../api/Api";
import { GeneralSettingsDto } from "../../../../../../../api/generalSettings/generalSettings.dto";
import { LangDto } from "../../../../../../../api/lang/lang.dto";
import { useQuery } from "../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import LangSwitch from "../../../../../../components/LangSwitch/LangSwitch";
import Tile from "../../../../../../components/Tile/Tile";
import FaqListWrapper from "./faq-list/faq-list-wrapper";
import GenSettBasicForm from "./GenSettBasicForm/GenSettBasicForm";
import HomeKnowledgeSectionForm from "./HomeKnowledgeSectionForm/HomeKnowledgeSectionForm";
import HomeSeoSectionForm from "./HomeSeoSectionForm/HomeSeoSectionForm";
import MediaListWrapper from "./media-list/media-list-wrapper";
import DeliveryPricesPolicyForm from "./DeliveryPricesPolicyForm/DeliveryPricesPolicyForm";
import LoadingBtn from "src/libs/buttons/Button/LoadingBtn";
import RelatedArticles from "./related-articles/related-articles-list";

const GeneralSettingsBody = ({ activeLang }: { activeLang: LangDto }) => {
  const { data: langData } = useQuery<GeneralSettingsDto, string>("generalSettings/get", [Api.GeneralSettings.Get, activeLang.prefix], {
    statusResetDelay: 1000,
  });

  return (
    <>
      <PanelRow>
        <Col size={[6, 6, 8, 12]}>
          <Tile>
            <h2 className="title">Home 🠚 Meta</h2>
            <GenSettBasicForm data={langData} />
          </Tile>
        </Col>
      </PanelRow>
      <PanelRow>
        <Col size={12}>
          <Tile>
            <MediaListWrapper data={langData} />
          </Tile>
        </Col>
      </PanelRow>
      <PanelRow>
        <Col size={[6, 6, 12, 12]}>
          <Tile>
            <h2 className="title">Home 🠚 Knowledge base</h2>
            <HomeKnowledgeSectionForm data={langData} pageId={"home"} />
            <hr  className="mt-4"/>
            <RelatedArticles sectionId={1} sectionName="home-page-knowledge-base-section" />
          </Tile>
        </Col>
        <Col size={[6, 6, 12, 12]}>
          <Tile>
            <h2 className="title">Home 🠚 SEO Section</h2>
            <HomeSeoSectionForm data={langData} pageId={"home"} />
          </Tile>
        </Col>
        <Col size={[6, 6, 12, 12]}>
          <Tile>
            <FaqListWrapper data={langData} />
          </Tile>
        </Col>
      </PanelRow>
    </>
  );
};

const GeneralSettings = () => {
  const { data: globalData } = useQuery<GeneralSettingsDto, string>("generalSettings/get", [Api.GeneralSettings.Get], {
    statusResetDelay: 1000,
  });

  const { call: refreshSitemap, status: refreshSitemapStatus } = useQuery<void, string>("UiSitemap/RunManualy", [Api.UiSitemap.RunManualy], {
    statusResetDelay: 1000,
    callOnLoad: false,
  });

  return (
    <div className="account-page">
      <BreadcrumbsItem to={RoutePath.PANEL_SETTINGS_GENERAL_SETTINGS} title="General Settings" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>General settings</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 6, 12, 12]}>
            <Tile>
              <h2 className="title">Cart 🠚 Delivery settings</h2>
              <DeliveryPricesPolicyForm data={globalData} pageId={"cart"} />
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={[6, 6, 12, 12]}>
            <Tile>
              <h2 className="title">Server settings</h2>
              <LoadingBtn status={refreshSitemapStatus} onClick={() => refreshSitemap()}>
                Sitemap refresh
              </LoadingBtn>
            </Tile>
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                  return lang.id === activeLang.id ? <GeneralSettingsBody activeLang={lang} key={index} /> : null;
                })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

export default GeneralSettings;
